import { clientType, decorType } from "@/utils/decor-types";
import request from "@/utils/request";
import { api } from "~/ui-domain";

/**
 * 添加页面
 * @param data
 * @returns {AxiosPromise<any>|Promise<unknown>|*}
 */
export function addPage(data) {
  // 客户端类型
  data.client_type = clientType.toUpperCase();
  return request({
    url: `${decorType}/pages`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data,
  });
}

/**
 * 更新页面数据
 * @param id
 * @param data
 * @returns {AxiosPromise<any>|Promise<unknown>|*}
 */
export function updatePage(id, data) {
  // 客户端类型
  data.client_type = clientType.toUpperCase();
  let url =
    decorType === "shop"
      ? `${decorType}/pages/edit`
      : `${decorType}/pages/${id}`;
  return request({
    url,
    method: "put",
    headers: { "Content-Type": "application/json" },
    data: { ...data, id },
  });
}

/**
 * 获取页面详情
 * @param id
 * @param loading
 * @returns {AxiosPromise<any>|Promise<unknown>|*}
 */
export function getPageDetail(id, loading = true) {
  // 判断是否店管端过来获取页面详情
  let url =
    decorType === "shop"
      ? `${decorType}/pages/view?id=${id}`
      : `${api.buyer}/pages/${id}`;
  return request({
    url,
    method: "get",
    loading,
  });
}
