<template>
  <div id="app">
    <home />
  </div>
</template>

<script>

import Home from '@/views/home'
export default {
  name: 'App',
  components: { Home }
}
</script>

<style>
@import "styles/normalize.css";
@import "styles/icon-fonts.css";
/* @import "styles/iconfont2.css"; */
@import "styles/van-icon.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
