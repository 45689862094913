import Accessories from "@/utils/accessories";
import * as Foundation from "@/utils/foundation";
import lodash from "lodash";
import Vue from "vue";
import Vuex from "vuex";
import * as types from "./types";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    previews: [],
    accessories: Foundation.cloneDeep(Accessories),
    activated: "",
    dragItem: {}, //左侧拖拽过来的元素,
    goodsTypeList: [],
    goodsProductTypeList: [],
  },
  getters: {
    previews: (state) => state.previews,
    accessories: (state) => state.accessories,
    activated: (state) => state.activated,
    dragItem: (state) => state.dragItem,
    goodsTypeList: (state) => state.goodsTypeList,
    goodsProductTypeList: (state) => state.goodsProductTypeList,
  },
  mutations: {
    /**
     * 设置预览项
     * @param state
     * @param item
     */
    [types.SET_PREVIEW_ITEM](state, item) {
      const _item = Foundation.cloneDeep(item);
      _item.__key = Foundation.uuid();
      const __index = item["__index"];
      if (/^[0-9]\d*$/.test(__index)) {
        delete _item[__index];
        state.previews.splice(__index, 0, _item);
      } else {
        state.previews.push(_item);
      }
    },
    /**
     * 移除预览项
     * @param state
     * @param key
     */
    [types.REMOVE_PREVIEW_ITEM](state, key) {
      const index = state.previews.findIndex((item) => item.__key === key);
      state.previews.splice(index, 1);
      const last = lodash.last(state.previews);
      state.activated = last ? last.__key : state.accessories[0].__key;
    },
    /**
     * 设置激活项
     * @param state
     * @param key
     */
    [types.SET_PREVIEW_ACTIVATED](state, key) {
      state.activated = key;
    },
    /**
     * 设置预览
     * @param state
     * @param previews
     */
    [types.SET_PREVIEWS](state, previews) {
      state.previews = previews;
    },
    /**
     * 设置页面参数
     * @param state
     * @param params
     */
    [types.SET_PAGE_PARAMS](state, params) {
      const index = state.accessories.findIndex(
        (item) => item.name === "page-params"
      );
      if (index === -1) return;
      state.accessories[index].data = params;
    },
    //设置左侧点击拖拽的元素
    setDragItem(state, item) {
      state.dragItem = item;
    },
    setGoodsTypeList(state, item) {
      state.goodsTypeList = item;
    },
    setGoodsProductTypeList(state, item) {
      state.goodsProductTypeList = item;
    },
  },
  actions: {
    /**
     * 设置预览项
     * @param commit
     * @param item
     */
    setPreviewItemAction({ commit }, item) {
      return commit(types.SET_PREVIEW_ITEM, item);
    },
    /**
     * 移除预览项
     * @param commit
     * @param key
     */
    removePreviewItemAction({ commit }, key) {
      commit(types.REMOVE_PREVIEW_ITEM, key);
    },
    /**
     * 设置激活项
     * @param commit
     * @param key
     */
    setPreviewActivatedAction({ commit }, key) {
      commit(types.SET_PREVIEW_ACTIVATED, key);
    },
    /**
     * 设置预览
     * @param commit
     * @param previews
     */
    setPreviewsAction({ commit }, previews) {
      commit(types.SET_PREVIEWS, previews);
    },
    /**
     * 设置页面参数
     * @param commit
     * @param params
     */
    setPageParamsAction({ commit }, params) {
      commit(types.SET_PAGE_PARAMS, params);
    },
  },
});

export default store;
