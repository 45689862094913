<template>
  <div>
    <div class="tabs-preview" :class="{ 'tabs-scroll': !$$FormData.is_scroll }">
      <div
        class="tabs-list"
        :class="{ 'list-scroll': $$FormData.is_scroll, 'tab-active': activeName === item.label }"
        v-for="(item, index) in list"
        :key="index"
        @click="changeTabs(item.label)"
      >
        {{ item.label }}
      </div>
    </div>
    <draggable v-model="componentsList" v-bind="componentsList" group="previews" class="preview-body" @add="handleDraggableAdd">
      <div class="tabs-container" :class="{ 'mask-disabled': activeName === '全部' }" :style="{ height: ($$FormData.container_height ) * 0.5 + 'px' }">
        <div v-if="activeName === '全部'" class="mask-all" :style="{ height: ($$FormData.container_height ) * 0.5 + 'px' }"></div>
        <transition-group class="group-box">
          <div
            v-for="(item, index) in tabShowComponents"
            :key="index + 'tabs'"
            class="item-preview"
            :class="{ 'tab-activated': activated === item.__key }"
            @click.stop="handleClickAccessory(item)"
          >
            
            <el-popover
              popper-class="tabs-popover"
              :placement="$$IsMobile ? 'right' : 'top'"
              trigger="hover"
              :open-delay="$$IsMobile ? 0 : 300"
              :append-to-body="false"
            >
              <div class="item-preview__mask" slot="reference">
                <component
                  :ref="`preview_${item.__key}`"
                  :is="`${item.name}-preview`"
                  :key="`preview-${item.__key}`"
                  :preview="item"
                  class="item-preview__inner"
                />
              </div>
              <div class="action-preview">
                <el-button type="danger" size="mini" @click="handleDeletePreview(item, index)">删除</el-button>
                <el-button size="mini" @click="handleCopyPreview(item, index)">复制</el-button>
              </div>
            </el-popover>
          </div>
        </transition-group>
      </div>
    </draggable>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Draggable from 'vuedraggable'
import lodash from 'lodash'
import * as Foundation from '@/utils/foundation'
export default {
  name: 'TabsAdvPreview',
  components: { Draggable },
  data() {
    return {
      list: [],
      activeName: 0,
      componentsList: [],
      tabShowComponents: [],
    }
  },
  mounted() {
    this.list = this.$$FormData.tabsList
    this.activeName = this.$$FormData.tabs_active
    this.componentsList = JSON.parse(JSON.stringify(this.$$FormData.componentsList))
    this.filterComponentsList()
  },
  computed: {
    ...mapGetters(['dragItem']),
  },
  methods: {
    ...mapActions(['setPreviewActivatedAction']),
    changeTabs(name) {
      this.activeName = name
      // this.$$FormData.tabs_active = name
      this.tabShowComponents = this.$$FormData.componentsList.filter((item) => item.tabName === this.activeName)
    },
    filterComponentsList() {
      this.tabShowComponents = this.$$FormData.componentsList.filter((item) => item.tabName === this.activeName)
    },
    handleDraggableAdd() {
      if (this.activeName === '全部') return
      this.$$FormData.componentsList.push({ ...this.dragItem, tabName: this.activeName })
      // 全部标签不让添加，其他标签栏添加后直接也往全部这里添加
      this.$$FormData.componentsList.push({ ...this.dragItem, tabName: '全部' })
      this.tabShowComponents.push({ ...this.dragItem, tabName: this.activeName })
      this.setPreviewActivatedAction(this.temp_key)
    },
    // 点击了附加项
    handleClickAccessory(item) {
      this.setPreviewActivatedAction(item.__key)
    },
    // 删除
    handleDeletePreview(val) {
      // const index = this.$$FormData.componentsList.findIndex((item) => item.__key === val.__key)
      this.$$FormData.componentsList=this.$$FormData.componentsList.filter(item=>item.__key !== val.__key)
      const last = lodash.last(this.$$FormData.componentsList)
      let activated = last ? last.__key : this.accessories[0].__key
      this.setPreviewActivatedAction(activated)
      this.filterComponentsList()
    },
    // 复制
    handleCopyPreview(item, index) {
      this.handleClickLabel(item, index)
      this.filterComponentsList()
    },
    // 计算已有预览项个数
    computePreviewNum(name) {
      return this.componentsList.filter((item) => item.name === name).length
    },
    handleClickLabel(item, index) {
      const { limit, title } = item.label
      if (limit !== -1 && this.computePreviewNum(item.name) >= limit) {
        return this.$message.error(`【${title}】最大数量为：${limit}个！`)
      }
      /^[0-9]\d*$/.test(index) && (item['__index'] = index)
      this.setComponentsListtem(item)
      const _index = /^[0-9]\d*$/.test(index) ? index : this.tabShowComponents.length - 1
      this.setPreviewActivatedAction(this.tabShowComponents[_index].__key)
    },
    setComponentsListtem(item) {
      const _item = Foundation.cloneDeep(item)
      _item.__key = Foundation.uuid()
      const __index = item['__index']
      if (/^[0-9]\d*$/.test(__index)) {
        delete _item[__index]
        this.$$FormData.componentsList.splice(__index, 0, _item)
      } else {
        this.$$FormData.componentsList.push(_item)
      }
    },
  },
  watch: {
    '$$FormData.tabs_active': {
      handler() {
        this.activeName = this.$$FormData.tabs_active
      },
      deep: true,
    }
  },
}
</script>
<style lang="scss" scoped>
.el-popover {
  // z-index: 3000 !important;
  left: 0;
}
.tabs-preview {
  width: 100%;
  padding: 20px;
  background: #fff;
  display: flex;
  align-items: center;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  overflow: auto;
  box-sizing: border-box;
  .tabs-list {
    padding: 0 20px;
    width: auto;
  }
  .list-scroll {
    display: inline-block;
    white-space: nowrap;
  }
  .tab-active {
    color: #4584ff;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: calc(100% - 40px);
      bottom: -6px;
      left: 20px;
      background: #4584ff;
    }
  }
}
.tabs-container {
  overflow-y: scroll;
  overflow-x: hidden;
  .action-preview {
    // position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    // z-index: 3000;
    .el-button + .el-button {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
  .mask-all {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.2);
  }

.item-preview {
  // position: relative;
  cursor: pointer;
  &.tab-activated .item-preview__mask {
    border: 1px solid #155bd4 !important;
  }
  .item-preview__mask {
    &:hover {
      border: 1px dashed #155bd4;
    }
  }
  .mask-disabled {
    cursor: not-allowed;
  }
  .item-preview__inner {
    position: relative;
    z-index: 1;
  }
}
.tabs-scroll {
  justify-content: space-between;
}
.tabs-preview::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.tabs-preview::-webkit-scrollbar-thumb {
  background-color: transparent;
}
</style>