import SettingTypes from '@/utils/setting-types'

export default{
 name: 'tabs-adv',
 decor_type: 'seller,admin',
 label: {
    title: '标签栏',
    icon: require('@/assets/icon-component-label_ImageAdv.png'),
    limit: 10
  },
  sort: 15,
  data:{
    tabsList:[{label:'全部',value: '全部',disabled:true}],
      // 选中
    tabs_active:null,
    is_scroll:false,
    // 内容高度
    container_height: 700,
    componentsList:[],
  },
  settings:{
    is_scroll:{
      type: SettingTypes.radio_button,
      label: '模式选择',
      options:[{label:'非滚动',value: false},{label:'滚动',value: true}],
    },
    tabs_active:{
      type: SettingTypes.radio_button,
      label: '默认选中列表',
      options:[{label:'',value:0}],
    },
    tabs_list:{
       type: SettingTypes.custom,
       component_name: 'TabsAdvSelector',
    },
    container_height: {
      type: SettingTypes.slider,
      label: '内容高度',
      props: {
        min: 0,
        max: 2750
      }
    }
  }
}