<template>
  <div class="decor-tabs" :class="{ 'tabs-scroll': !$$FormData.is_scroll }">
    <div class="tabs-list" :class="{ 'list-scroll': $$FormData.is_scroll, 'tab-active': activeName === item.label }" v-for="(item, index) in list" :key="index" @click="changeTabs(item.label)">
      {{ item.label }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'tabsPreview',
  data() {
    return {
      activeName: '商品',
      list: [],
    }
  },
  mounted() {
    this.list = this.$$FormData.tabsList
    this.activeName = this.$$FormData.tabs_active
  },
  methods: {
    changeTabs(name) {
      this.activeName = name
    },
  },
  watch: {
    '$$FormData.tabs_active': {
      handler() {
        this.activeName = this.$$FormData.tabs_active
      },
      deep: true,
    }
  },
}
</script>
<style lang="scss" scoped>
.decor-tabs {
  width: 100%;
  padding: 10px 20px;
  background: #fff;
  display: flex;
  align-items: center;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  overflow: auto;
  box-sizing: border-box;
  .tabs-list {
    padding: 0 10px;
    width: auto;
  }
  .list-scroll {
    display: inline-block;
    white-space: nowrap;
  }
  .tab-active {
    color: #4584ff;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: calc(100% - 40px);
      bottom: -6px;
      left: 20px;
      background: #4584ff;
    }
  }
}
.tabs-scroll {
  justify-content: space-around;
}
.tabs-preview::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.tabs-preview::-webkit-scrollbar-thumb {
  background-color: transparent;
}
</style>