<template>
  <div class="reservation-coupon">
    <p class="reservation-coupon__title">预约模块</p>
    <p class="reservation-coupon__subtitle">此模块无须预览，提供占位即可。</p>
  </div>
</template>
  
  <script>
export default {
  name: 'ReservationPreview',
}
</script>
  
  <style lang="scss" scoped>
.reservation-coupon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  margin: 0 auto;
  background-color: #fff;
  overflow: hidden;
  &__title {
    font-size: 15px;
    text-align: center;
  }
  &__subtitle {
    font-size: 12px;
    color: #999999;
    margin-top: 10px;
    text-align: center;
  }
}
</style>
  