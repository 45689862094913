import { render, staticRenderFns } from "./RadioButton.vue?vue&type=template&id=fce43f48&scoped=true"
import script from "./RadioButton.vue?vue&type=script&lang=js"
export * from "./RadioButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fce43f48",
  null
  
)

export default component.exports